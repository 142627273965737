import React from 'react'
import iconsquare from '../../assets/images/icon-square.png'
import Header from '../../header'
import axios from 'axios';
import config from '../../config'
import { v4 as uuidv4 } from 'uuid';
class AlphabetsLondon extends React.Component{
    constructor(props)
    {
        super(props)
        {
            this.state={
                alphabetslondon:[],
                selectedItems:JSON.parse(sessionStorage.getItem('alphabetlondon')),
                alphabetlondonloader : false
            }
        }
        this.alphabetsback = this.alphabetsback.bind(this)
        this.alphabetsnext = this.alphabetsnext.bind(this)
        this.selectlondonfont = this.selectlondonfont.bind(this);
    }
    alphabetsback()
    {
        this.props.history.push('/alphabets');
    }
    alphabetsnext()
    {
        this.props.history.push('/exclusiveicons');
    }
    componentDidMount()
    {
        this.setState({
            alphabetlondonloader: true
        })
        let store_id = sessionStorage.getItem('store_id');
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}getAssests?type=alphabets-london&store_id=${store_id}`
        axios.get(apiUrl , {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
            if(response.data.success == 1)
            {
                this.setState({
                    alphabetlondonloader: false
                })
                this.setState({alphabetslondon:response.data.data})
            }
        })
        .catch((err) => {

        });
    }
    selectlondonfont(id,src,index, value)
    {
        const {selectedItems } = this.state;
        if(value == 1)
        {
        selectedItems.push({
            id:id,
            alphabetlondonid : uuidv4(),
            img:src
        })
          this.setState({
              tempstate:selectedItems
          })
          const filtered = selectedItems.filter(el => {
            return el != null && el != '';
          });
          sessionStorage.setItem('alphabetlondon',JSON.stringify(filtered));
        }
        else{
            let i=0;
            for(i= 0; i <= selectedItems.length;i++)
            {
                if(selectedItems[i])
                {
                    if(selectedItems[i].id == id)
                    {
                        delete selectedItems[i];
                        break;
                    }
                }
            }
            var filtered = selectedItems.filter(function (el) {
                return el != null;
            });
            this.setState({
                tempstate:selectedItems
            })
            sessionStorage.setItem('alphabetlondon',JSON.stringify(filtered));
        }
    }
    render()
    {
        const {selectedItems,alphabetlondonloader} = this.state;
        return(
            <div>
                <Header/>
                <section className="home-register-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div design-menu-sec design-logo-sec design-icon-sec">
                                    <div className="form-logo-section sec-center">
                                        <a href="#/register"><img src={iconsquare} alt="logo" className="img-fluid brand-logo"/></a>
                                    </div>
                                    <h3>London Font</h3>
                                    <h4 class="m30">Alphabets | Symbols | Numbers</h4>
                                    {alphabetlondonloader ? 
                                    <div class="spinner-border logo" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <div>
                                        <div className="col-md-12 sec-center left">
                                            <div className="design-logo-grid-outer design-graphics-grid-outer">
                                                <div className="design-logo-grid-inner">
                                                    {this.state.alphabetslondon && this.state.alphabetslondon.map((item,index)=>{
                                                        return(
                                                            <div key={index} className="logo-grid-box">
                                                                <div className={selectedItems.filter(x=>x.id==item.id).length > 0 ? "logo-grid-box-img active" : "logo-grid-box-img"}  onClick={this.selectlondonfont}>
                                                                    <img id={item.id} src={item.image_path} alt="logo1" className="img-fluid"/>
                                                                </div>
                                                                <ul>
                                                                    <li><button  disabled={selectedItems.filter(x=>x.id==item.id).length == 0} onClick={(e) => this.selectlondonfont(item.id,item.image_path,'-1')}>-</button></li>
                                                                    <li>{selectedItems.filter(x=>x.id==item.id).length}</li>
                                                                    <li><button disabled={selectedItems.filter(x=>x.id==item.id).length == 5} onClick={(e) => this.selectlondonfont(item.id,item.image_path,index,'1')}>+</button></li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <div className="col-md-12 sec-center left">
                                        <div className="footer-btn"> 
                                            <div className="footer-left-btn"> 
                                                <button onClick={this.alphabetsback} className="black_btn">Back</button>
                                            </div>
                                            <div className="footer-right-btn"> 
                                                <button onClick={this.alphabetsnext} className="black_btn">Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default AlphabetsLondon;
import React from 'react'
import Header from '../header'
import iconsquare from '../assets/images/icon-square.png'
import ashfront from '../assets/images/ash-front.png'
import blackfront from '../assets/images/black-front.png'
import whitefront from '../assets/images/white-front.png'
import axios from 'axios';
import config from '../config'
import * as htmlToImage from 'html-to-image';
import LoadingOverlay from 'react-loading-overlay';
class Tshirtfront extends React.Component{
    constructor(props)
    {
        super(props)
        this.state={
            orderplacedvalid:false,
            clickcount : 0,
            disablebutton:false
        }
        this.tshirtfrontback = this.tshirtfrontback.bind(this);
        this.tshirtfrontAdd = this.tshirtfrontAdd.bind(this);
        this.tshirtfrontprint = this.tshirtfrontprint.bind(this);
    }
    tshirtfrontback()
    {
        this.props.history.push('/layout')
    }
    tshirtfrontAdd()
    {
        this.props.history.push('/designs')
    }
    downloadPNGFromAnyImageSrc(src, hit)
    {
        var img = new Image;
        img.onload = function(){
        var canvas = convertImageToCanvas(img);
        var pngImage =  convertCanvasToImage(canvas);
        };
        img.src = src;

        function convertImageToCanvas(image) {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        canvas.getContext("2d").drawImage(image, 0, 0);
        //alert('canvasconverter')
        return canvas;
        }
        //let imagevalue = false;
        function convertCanvasToImage(canvas) {
            var image = new Image();
                image.src = canvas.toDataURL("image/png");
                //alert('imageconverter')
                //console.log("image.src ",image.src )
                sessionStorage.setItem('prev_image',image.src)
                // var interval = setInterval(() => {
                //     if(localStorage.getItem('prev_image') != '')
                //     {
                //         imagevalue = true;
                //         clearInterval(interval);
                //     }
                // }, 200);
                if(hit ==1)
                {
            setTimeout(() => {
            let apiDynamicUrlAddress = config.default.api.host;
                let apiUrl = `${apiDynamicUrlAddress}placeOrder`
                var formData = new FormData();
                let customer_id = sessionStorage.getItem('customer_id')
                let user_id = sessionStorage.getItem('user_id')
                let material_id = sessionStorage.getItem('material_id')
                let logos = sessionStorage.getItem('logos')
                let graphics = sessionStorage.getItem('graphics')
                let alphabetblack = sessionStorage.getItem('alphabetblack')
                let alphabetblue = sessionStorage.getItem('alphabetblue')
                let alphabetlondon = sessionStorage.getItem('alphabetlondon')
                let prev_image =  image.src
                let print_image = sessionStorage.getItem('imagedata');
                let body = [{
                    "logos":logos ? logos : '',
                    "graphics":graphics ? graphics : '',
                    "alphabetblack":alphabetblack ? alphabetblack : '',
                    "alphabetblue":alphabetblue ? alphabetblue : '',
                    "alphabetlondon":alphabetlondon ? alphabetlondon :'',
                }]
                formData.append("customer_id", customer_id ? customer_id : '');
                formData.append("material_id", material_id ? material_id : '');
                formData.append("status",'order');
                formData.append("prev_image",  prev_image ?  prev_image : '');
                formData.append("print_image", print_image ? print_image : '');
                formData.append("placed_by",  user_id ? user_id : '');
                formData.append("processed_by",'');
                formData.append("json", JSON.stringify(body));
                axios.post(apiUrl ,formData, {
                    crossDomain : true,
                    enablePreflight : false
                }).then((response) => {
                    if(response.data.success == 1)
                    {
                        this.setState({
                            orderplacedvalid:true
                        })
                    }
                    else{
                        this.setState({
                            orderplacedinvalid:'Order is not Placed Successfully',
                            disablebutton : false
                        })
                    }
                })
                .catch((err) => {
                });
                }, 3000);
            }
        }
        if(hit == 1)
        {
            setTimeout(() => {
                this.props.history.push('/confirmation');
            }, 6000);
        }
    }
    tshirtfrontprint(e)
    {
        e.preventDefault();
        this.setState({
            disablebutton : true
        })
        let gettempimg = sessionStorage.getItem('tempimg');
        this.downloadPNGFromAnyImageSrc(gettempimg, 1)
    }
    componentDidMount()
    {
        this.setState({
            disablebutton : true
        })
        let tempimg;
        setTimeout(() => {
            htmlToImage.toSvg(document.getElementById('preview-section'))
        .then(function (dataUrl) {
            var img = new Image();
            img.src = dataUrl;
            tempimg = img.src;
            sessionStorage.setItem('tempimg',img.src)
        });
        setTimeout(() => {
            this.downloadPNGFromAnyImageSrc(tempimg, 0)
        }, 6000);
    }, 6000);
    setTimeout(() => {
        this.setState({
            disablebutton : false
        })
    }, 14000);
    }
    render()
    {
        const {disablebutton} = this.state
        let positionvalue = sessionStorage.getItem('imagedata')
        // let position =  JSON.parse(positionvalue)
        let selectedcolorvalue = sessionStorage.getItem('selectedTshirt');
        let selectedcolor = JSON.parse(selectedcolorvalue)
        return(
            <div>
                <div className="screen_loader">
                <LoadingOverlay
                    active={disablebutton}
                    spinner
                    text='Loading your content...'
                >
                <Header/>
                <section class="home-register-section">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-12 col-lg-12">
                                <div class="home-register-form-div design-menu-sec design-logo-sec design-layout-div">
                                    <div class="form-logo-section sec-center">
                                        <a href="#/register"><img src={iconsquare} alt="logo" class="img-fluid brand-logo"/></a>
                                    </div>
                                    <h3>Mock Up</h3>
                                    <h4></h4>
                                    <div class="col-md-12 sec-center left" id="preview-section">
                                        <div class="design-mock-outer">
                                            <div class="design-mock-outer-image-box" id="tshirt-front-preview">
                                                <div class="design-mock-outer-image-box-shirt">
                                                    {
                                                    selectedcolor[0].color == 'Gray' ? 
                                                    <img src={ashfront} alt="t-shirt-front" class="img-fluid"/>
                                                    :
                                                    selectedcolor[0].color == 'Black' ? 
                                                    <img src={blackfront} alt="t-shirt-front" class="img-fluid"/>
                                                    :
                                                    <img src={whitefront} alt="t-shirt-front" class="img-fluid"/>
                                                    }
                                                </div>
                                                <div class="design-mock-outer-image-box-logo">
                                                    <img src={positionvalue} alt="t-shirt-icon" class="img-fluid"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 sec-center left">
                                        <div class="footer-btn footer-btn-three footer-btn-large"> 
                                            <div class="footer-left-btn"> 
                                                <button onClick={this.tshirtfrontback} class="black_btn">Back</button>
                                            </div>
                                            <div class="footer-center-btn"> 
                                                <button onClick={this.tshirtfrontAdd} class="black_btn">Add</button>
                                            </div>
                                            <div class="footer-right-btn"> 
                                                <button  onClick={this.tshirtfrontprint} class="black_btn" disabled={disablebutton}>Print</button>
                                                {/* {disablebutton ? 
                                                    // <div class="spinner-border" role="status">
                                                    //     <span class="sr-only">Loading...</span>
                                                    // </div>
                                                    <LoadingOverlay
                                                        active={disablebutton}
                                                        spinner
                                                        text='Loading your content...'
                                                        >
                                                    </LoadingOverlay>
                                                    :
                                                    null
                                                } */}
                                            </div>
                                        </div>
                                        {/* <div className="form-group">
                                            <div class={this.state.orderplacedvalid ? 'email-success-msg' : 'email-error-msg' }>{this.state.orderplacedvalid}</div>
                                            <div class={this.state.orderplacedinvalid ? 'email-error-msg' : 'email-error-msg' }>{this.state.orderplacedinvalid}</div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
            </LoadingOverlay>
            </div>
        </div>
        )
    }
}
export default Tshirtfront;
import React from 'react'
import Header from '../../header'
import iconsquare from '../../assets/images/icon-square.png'
class Designs extends React.Component{
    constructor(props)
    {
        super(props)
        {
            this.state={

            }
        }
        this.designback=this.designback.bind(this);
        this.logos = this.logos.bind(this);
        this.graphics = this.graphics.bind(this);
        this.alphabets  =this.alphabets.bind(this);
        this.exclusiveicons = this.exclusiveicons.bind(this);
        this.designext = this.designext.bind(this);
    }
    designback()
    {
        this.props.history.push('/tshirt-selection')
    }
    designext()
    {
        this.props.history.push('/logos');
    }
    logos()
    {
        this.props.history.push('/logos')
    }
    graphics()
    {
        this.props.history.push('/graphics')
    }
    alphabets()
    {
        this.props.history.push('/alphabets')
    }
    exclusiveicons()
    {
        this.props.history.push('/exclusiveicons')
    }
    render()
    {
        return(
            <div>
                <Header/>
                <section className="home-register-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div design-menu-sec">
                                    <form>
                                    <div className="form-logo-section sec-center">
                                        <a href="#/register"><img src={iconsquare} alt="logo" className="img-fluid brand-logo"/></a>
                                    </div>
                                    <h3>Designs</h3>
                                    <div className="col-md-12 sec-center left">
                                        <div className="design-menu-inner">
                                            <ul>
                                                <li><button onClick={this.logos}>Logos</button></li>
                                                <li><button onClick={this.graphics}>Graphics</button></li>
                                                <li><button onClick={this.alphabets}>Alphabets</button></li>
                                                <li><button onClick={this.exclusiveicons}>SG Exclusive Icon</button></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-12 sec-center left">
                                        <div className="footer-btn"> 
                                            <div className="footer-left-btn"> 
                                                <button className="black_btn" onClick={this.designback}>Back</button>
                                            </div>
	                                    <div className="footer-right-btn"> 
                                            <button className="black_btn" onClick={this.designext}>Next</button>
	                                    </div>
	                                    </div>
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Designs;
import React from 'react';
import Header from '../header'
import iconsquare from '../assets/images/icon-square.png'
import axios from 'axios';
import config from '../config'
import * as htmlToImage from 'html-to-image';
import $ from 'jquery';
const download =  require('downloadjs')
class PreviewDetails extends React.Component{
    constructor(props)
    {
        super(props);
        this.state={
            orderId:'',
            data:[],
            disable:true
        }
        this.completeorder = this.completeorder.bind(this);
        this.downloadimage = this.downloadimage.bind(this);
        this.backbutton = this.backbutton.bind(this);
        this.checkbox = this.checkbox.bind(this);
    }
    checkbox(e)
    {
        this.setState({
            disable : !this.state.disable
        })
    }
    backbutton()
    {
        this.props.history.push('/dashboard/queuing-jobs');
    }
    componentDidMount(e)
    {
        var url = this.props.location.pathname;
        //console.log("url",url)
        var id = url.substring(url.lastIndexOf('/') + 1);
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}getOrderInfo`
        var formData = new FormData();
        formData.append("getOrderDetails", '1');
        formData.append("orderId", id);
        axios.post(apiUrl , formData, {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
        //console.log("response",response)
        if(response.data.success == 1)
        {
            this.setState({
                data : response.data.data
            })
        }
        else{
            // toast.error("Register is not Succesfully Submitted.", {
            //     position: toast.POSITION.TOP_RIGHT,
            //     autoClose: 1500
            // });
        }
        })
        .catch((err) => {  
        });
    }
    completeorder(e)
    {
        e.preventDefault();
        var url = this.props.location.pathname;
        //console.log("url",url)
        var id = url.substring(url.lastIndexOf('/') + 1);
        //console.log("id",id)
        let user_id = sessionStorage.getItem('user_id')
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}changeOrderStatus`
        var formData = new FormData();
        formData.append("changeOrder", '1');
        formData.append("orderId", id);
        formData.append("user_id", user_id);
        axios.post(apiUrl , formData, {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
        //console.log("response",response)
        if(response.data.success == 1)
        {
            this.props.history.push('/dashboard/completed-jobs')
        }
        else{
            // toast.error("Register is not Succesfully Submitted.", {
            //     position: toast.POSITION.TOP_RIGHT,
            //     autoClose: 1500
            // });
        }
        })
        .catch((err) => {  
        });
        //this.props.history.push('/dashboard/completed-jobs')
    }
    downloadimage(data)
    {
        var a = $("<a>")
        .attr("href", data.print_image)
        .attr("download", "preview-image-"+data.customer_id+".png")
        .appendTo("body");
        a[0].click();
        a.remove();
    }
    render()
    {
        const {data} = this.state;
        //console.log("state",data)
        let Cstate=Object.values(this.state);
        let Istate =Cstate.map(x => x ?true:false).filter( x => x)
        return(
            <div>
                <Header/>
                <section className="home-register-section dashboard section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div dashboard-div detail-outer-section">
                                    <div className="form-logo-section sec-center">
                                        <a><img src={iconsquare} alt="logo" className="img-fluid brand-logo"/></a>
                                    </div>
                                    <div className="detail-inner-full-section">
                                        <div className="detail-inner-full-section-left">
                                            <h3>Details</h3>
                                            <form className="home-register-form detail-form">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" value={data.full_name} id="name"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="tel" className="form-control" value={data.phone} id="phone"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                    <input type="email" className="form-control" value={data.email} id="email"/>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-12">
                                                    <div className="form-group place-bold-div">
                                                    <label>Size</label>
                                                    <input type="text" className="form-control" value={data.size} id="size"/>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-12">
                                                    <div className="form-group place-bold-div">
                                                    <label>Color</label>
                                                    <input type="text" className="form-control" value={data.color} id="color"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group place-bold-div">
                                                    <label>Print Side</label>
                                                    <input type="text" className="form-control" value={data.material_type} id="print-side"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group checkbox-div">
                                                        <input type="checkbox" 
                                                        id="checktxt" 
                                                        name="click-txt" 
                                                        value="" 
                                                        required
                                                        onChange={this.checkbox}
                                                        />
                                                        <label htmlFor="checktxt">Please download the image and save before pressing the complete button.</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="bottom-arrow-div back">
                                                        <button className="bottom-arrow-btn" onClick={() => this.backbutton()}>
                                                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                    <div className="bottomcomplete-button">
                                                        <button type="submit" className="btn register-btn"  disabled={this.state.disable} onClick={this.completeorder}>Complete</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="detail-inner-full-section-right">
                                            <h3>Preview</h3>
                                            <div className="design-mock-outer">
                                                <div className="design-mock-outer-image-box">
                                                    <div className="design-mock-outer-image-box-shirt">
                                                        <img src={data.prev_image} alt="t-shirt-front" className="img-fluid" id="preview-image"/>
                                                    </div>
                                                    <div className="design-mock-outer-image-box-logo">
                                                        {/* <img src="./images/logos/adidas-logo-02.png" alt="t-shirt-icon" className="img-fluid"/> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bottom-arrow-div">
                                                <button className="btn black_btn" onClick={() => this.downloadimage(data)}>
                                                    {/* <i className="fa fa-arrow-down" aria-hidden="true"></i> */}
                                                    Download
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default PreviewDetails;
import React from 'react'
import Header from '../header'
import iconsquare from '../assets/images/icon-square.png'
import resetpng from '../assets/images/reset.png'
import trashpng from '../assets/images/trash.png'
import whitefront from '../assets/images/white-front.png'
import { fabric } from 'fabric';
class Layout extends React.Component{
    constructor(props)
    {
        super(props)
        {
            this.state={
                imageposition:[],
                disablebutton : false,
                disabledelete  : false,
                position:JSON.parse(sessionStorage.getItem('position')),
                canvas : ''
            }
            this.layoutback= this.layoutback.bind(this);
            this.layoutAdd= this.layoutAdd.bind(this);
            this.layoutprint = this.layoutprint.bind(this);
            this.resetLayout = this.resetLayout.bind(this);
            this.deleteLayout = this.deleteLayout.bind(this)
            this.fabriccanvas = this.fabriccanvas.bind(this);
        }
    }
    deleteLayout()
    {
        const{canvas} = this.state
        if(window.confirm('Are you sure you want to delete the icon?'))
        {
            let activeobj = sessionStorage.getItem('activeobject');
            let logovalue = sessionStorage.getItem('logos');
            let logo = JSON.parse(logovalue);
            let graphicsvalue = sessionStorage.getItem('graphics');
            let graphics = JSON.parse(graphicsvalue);
            let alphabetblackvalue = sessionStorage.getItem('alphabetblack');
            let alphabetblack = JSON.parse(alphabetblackvalue);
            let alphabetbluevalue = sessionStorage.getItem('alphabetblue');
            let alphabetblue = JSON.parse(alphabetbluevalue);
            let alphabetlondonvalue = sessionStorage.getItem('alphabetlondon');
            let alphabetlondon = JSON.parse(alphabetlondonvalue);
            let sgexclusivevalue  = sessionStorage.getItem('sg-exclusive');
            let sgexclusive = JSON.parse(sgexclusivevalue);
            let postioncord = sessionStorage.getItem('position')
            let postioncordvalue = JSON.parse(postioncord)
        let i;
        for(i= 0; i <= logo.length;i++)
        {
            if(logo[i])
            {
                if(logo[i].logoid == activeobj)
                {
                    delete logo[i];
                    break;
                }
            }
        }
        const filtered = logo.filter(el => {
            return el != null && el != '';
            });
            sessionStorage.setItem('logos',JSON.stringify(filtered));
            sessionStorage.removeItem('activeobject');
            for(i= 0; i <= graphics.length;i++)
        {
            if(graphics[i])
            {
                if(graphics[i].graphicsid == activeobj)
                {
                    delete graphics[i];
                    break;
                }
            }
        }
        const filteredgraphics = graphics.filter(el => {
            return el != null && el != '';
            });
            sessionStorage.setItem('graphics',JSON.stringify(filteredgraphics));
            sessionStorage.removeItem('activeobject');
            for(i= 0; i <= alphabetblack.length;i++)
            {
                if(alphabetblack[i])
                {
                    if(alphabetblack[i].alphabetblackid == activeobj)
                    {
                        delete alphabetblack[i];
                        break;
                    }
                }
            }
            const filteredalphabetblack = alphabetblack.filter(el => {
                return el != null && el != '';
            });
            sessionStorage.setItem('alphabetblack',JSON.stringify(filteredalphabetblack));
            sessionStorage.removeItem('activeobject');
            for(i= 0; i <= alphabetblue.length;i++)
            {
                if(alphabetblue[i])
                {
                    if(alphabetblue[i].alphabetblueid == activeobj)
                    {
                        delete alphabetblue[i];
                        break;
                    }
                }
            }
            const filteredalphabetblue = alphabetblue.filter(el => {
                return el != null && el != '';
            });
            sessionStorage.setItem('alphabetblue',JSON.stringify(filteredalphabetblue));
            sessionStorage.removeItem('activeobject');
            for(i= 0; i <= alphabetlondon.length;i++)
            {
                if(alphabetlondon[i])
                {
                    if(alphabetlondon[i].alphabetlondonid == activeobj)
                    {
                        delete alphabetlondon[i];
                        break;
                    }
                }
            }
            const filteredalphabetlondon = alphabetlondon.filter(el => {
                return el != null && el != '';
            });
            sessionStorage.setItem('alphabetlondon',JSON.stringify(filteredalphabetlondon));
            sessionStorage.removeItem('activeobject');
            for(i= 0; i <= sgexclusive.length;i++)
            {
                if(sgexclusive[i])
                {
                    if(sgexclusive[i].sgexclusiveid == activeobj)
                    {
                        delete sgexclusive[i];
                        break;
                    }
                }
            }
            const filteredsgexclusive = sgexclusive.filter(el => {
                return el != null && el != '';
            });
            sessionStorage.setItem('sg-exclusive',JSON.stringify(filteredsgexclusive));
            sessionStorage.removeItem('activeobject');
            for(i= 0; i <= postioncordvalue.length;i++)
            {
                if(postioncordvalue[i])
                {
                    if(postioncordvalue[i].id == activeobj)
                    {
                        delete postioncordvalue[i];
                        break;
                    }
                }
            }
            const filteredposition = postioncordvalue.filter(el => {
                return el != null && el != '';
            });
            sessionStorage.setItem('position',JSON.stringify(filteredposition))
            window.location.reload()
            }
        }
    resetLayout()
    {
        if(window.confirm('Are you sure you want to reset the Layout?'))
        {
            this.setState({
                disablereset : true
            })
            sessionStorage.setItem('logos',JSON.stringify([]));
            sessionStorage.setItem('graphics',JSON.stringify([]));
            sessionStorage.setItem('alphabetblack',JSON.stringify([]));
            sessionStorage.setItem('alphabetblue',JSON.stringify([]));
            sessionStorage.setItem('alphabetlondon',JSON.stringify([]));
            sessionStorage.setItem('sg-exclusive',JSON.stringify([]));
            sessionStorage.setItem('position',JSON.stringify([]));
            this.setState({
                disablereset : false
            })
            this.props.history.push('/designs')
        }
    }
    layoutback()
    {
        this.props.history.push('/designs')
    }
    layoutAdd()
    {
        this.props.history.push('/designs')
    }
    layoutprint()
    {
        const {canvas} = this.state;
        canvas.discardActiveObject();
        canvas.renderAll()
        sessionStorage.removeItem('deselectObject');
        setTimeout(() => {
            var mycanvas = document.getElementById("canvas");
            //console.log("mycanvas",mycanvas)
            var image = new Image();
            image.src = mycanvas.toDataURL();
            //console.log("image",image.src)
            sessionStorage.setItem('imagedata',image.src)
            let selectedTshirtvalue = sessionStorage.getItem('selectedTshirt');
            let selectedTshirt = JSON.parse(selectedTshirtvalue)
            if(selectedTshirt[0].side == 'T-Shirt Front')
            {
                this.props.history.push('/tshirt-front')
            }
            if(selectedTshirt[0].side == 'T-Shirt Back')
            {
                this.props.history.push('/tshirt-back')
            }
            }, 2000);
    }
    componentDidMount()
    {
        this.fabriccanvas();
    }
    fabriccanvas() {
    var canvas=new fabric.Canvas("canvas");
    this.setState({
        canvas : canvas
    })
    canvas.loadFromJSON(this.json,CallBack, function(o,object){
    canvas.setActiveObject(object);
    });
    canvas.selection = false;
    function CallBack(){
    canvas.renderAll();
    canvas.toSVG();
    }
    let postioncord = sessionStorage.getItem('position')
    let postioncordvalue = JSON.parse(postioncord)
    let logo = sessionStorage.getItem('logos')
    let logoarray = JSON.parse(logo)
    var HideControls = {
        'mb': false,
        'mr': false,
        'ml': false,
        'mt': false,
    };
    logoarray && logoarray.map((item,index)=>{
        let logoindex =postioncordvalue && postioncordvalue.findIndex(items => item.logoid == items.id)
        let top = 0;
        let left = 0;
        let angle = 0;
        let scalex = 0.1
        if(postioncordvalue)
        {
            if(postioncordvalue[logoindex])
            {
                top = postioncordvalue[logoindex].imagecordtop;
                left = postioncordvalue[logoindex].imagecordleft;
                angle = postioncordvalue[logoindex].angle
                scalex =  postioncordvalue[logoindex].scalex
            }
        }
    fabric.Image.fromURL(item.img, function(img) {
        const width = img.width;
        const height = img.height;
        // console.log("width",width)
        // console.log("height",height)
        img.setControlsVisibility(HideControls);
        img.set({
        transparentCorners: true,
        cornerColor: 'white',
        cornerStrokeColor: 'black',
        borderColor: 'black',
        cornerSize: 20,
        padding: 5,
        cornerStyle: 'circle',
        top:  top,
        left: left,
        ml:false,
        id:item.logoid,
        name : 'logo',
        angle : angle,
    });
    img.scale(scalex);
    canvas.add(img);
    canvas.renderAll();
  },{crossOrigin: 'anonymous'});  
});
    let graphics  = sessionStorage.getItem('graphics');
    let graphicsarray = JSON.parse(graphics);
    var HideControls = {
        'mb': false,
        'mr': false,
        'ml': false,
        'mt': false,
      };
    graphicsarray && graphicsarray.map((item,index)=>{
    let graphicsindex =postioncordvalue && postioncordvalue.findIndex(items => item.graphicsid == items.id)
    let top = 0;
    let left = 100;
    let angle = 0;  
    let scalex = 0.1
    if(postioncordvalue)
    {
        if(postioncordvalue[graphicsindex])
        {
            top = postioncordvalue[graphicsindex].imagecordtop;
            left = postioncordvalue[graphicsindex].imagecordleft;
            angle = postioncordvalue[graphicsindex].angle
            scalex =  postioncordvalue[graphicsindex].scalex
        }
    }
    fabric.Image.fromURL(item.img, function(img) {
    img.setControlsVisibility(HideControls);
    img.set({
    transparentCorners: true,
    cornerColor: 'white',
    cornerStrokeColor: 'black',
    borderColor: 'black',
    cornerSize: 20,
    padding: 0,
    cornerStyle: 'circle',
    top: top,
    left: left,
    id:item.graphicsid,
    name : 'graphics',
    angle:angle,
  });
    img.scale(scalex);
    canvas.add(img);
    canvas.renderAll();
},{crossOrigin: 'anonymous'}); 
});
    let alphabetblack  = sessionStorage.getItem('alphabetblack');
    let alphabetblackarray = JSON.parse(alphabetblack);
    var HideControls = {
        'mb': false,
        'mr': false,
        'ml': false,
        'mt': false,
      };
    alphabetblackarray && alphabetblackarray.map((item,index)=>{
    let alphabetblackindex =postioncordvalue && postioncordvalue.findIndex(items => item.alphabetblackid == items.id)
    let top = 0;
    let left = 200;
    let angle = 0;  
    let scalex = 0.1
    if(postioncordvalue)
    {
        if(postioncordvalue[alphabetblackindex])
        {
            top = postioncordvalue[alphabetblackindex].imagecordtop;
            left = postioncordvalue[alphabetblackindex].imagecordleft;
            angle = postioncordvalue[alphabetblackindex].angle
            scalex =  postioncordvalue[alphabetblackindex].scalex
        }
    }
    fabric.Image.fromURL(item.img, function(img) {
    img.setControlsVisibility(HideControls);
    img.set({
    transparentCorners: true,
    cornerColor: 'white',
    cornerStrokeColor: 'black',
    borderColor: 'black',
    cornerSize: 20,
    padding: 0,
    cornerStyle: 'circle',
    top:top,
    left:left,
    id:item.alphabetblackid,
    name : 'alphabetblack',
    angle:angle,
  });
    img.scale(scalex);
    canvas.add(img);
    canvas.renderAll();
},{crossOrigin: 'anonymous'}); 
});
    let alphabetblue  = sessionStorage.getItem('alphabetblue');
    let alphabetbluearray = JSON.parse(alphabetblue);
    var HideControls = {
        'mb': false,
        'mr': false,
        'ml': false,
        'mt': false,
      };
    alphabetbluearray && alphabetbluearray.map((item,index)=>{
    let alphabetblueindex =postioncordvalue && postioncordvalue.findIndex(items => item.alphabetblueid == items.id)
    let top = 0;
    let left = 300;
    let angle = 0; 
    let scalex= 0.1
    if(postioncordvalue)
    {
        if(postioncordvalue[alphabetblueindex])
        {
            top = postioncordvalue[alphabetblueindex].imagecordtop;
            left = postioncordvalue[alphabetblueindex].imagecordleft;
            angle = postioncordvalue[alphabetblueindex].angle
            scalex =  postioncordvalue[alphabetblueindex].scalex
        }
    }
    fabric.Image.fromURL(item.img, function(img) {
    img.setControlsVisibility(HideControls);
    img.set({
    transparentCorners: true,
    cornerColor: 'white',
    cornerStrokeColor: 'black',
    borderColor: 'black',
    cornerSize: 20,
    padding: 0,
    cornerStyle: 'circle',
    top:top,
    left:left,
    id:item.alphabetblueid,
    name : 'alphabetblue',
    angle:angle,
  });
    img.scale(scalex);
    canvas.add(img);
    canvas.renderAll();
},{crossOrigin: 'anonymous'}); 
});
    let alphabetlondon  = sessionStorage.getItem('alphabetlondon');
    let alphabetlondonarray = JSON.parse(alphabetlondon);
    var HideControls = {
        'mb': false,
        'mr': false,
        'ml': false,
        'mt': false,
      };
    alphabetlondonarray && alphabetlondonarray.map((item,index)=>{
    let alphabetlondonindex =postioncordvalue && postioncordvalue.findIndex(items => item.alphabetlondonid == items.id)
    let top = 100;
    let left = 0;
    let angle = 0; 
    let scalex = 0.1
    if(postioncordvalue)
    {
        if(postioncordvalue[alphabetlondonindex])
        {
            top = postioncordvalue[alphabetlondonindex].imagecordtop;
            left = postioncordvalue[alphabetlondonindex].imagecordleft;
            angle = postioncordvalue[alphabetlondonindex].angle
            scalex =  postioncordvalue[alphabetlondonindex].scalex
        }
    }
    fabric.Image.fromURL(item.img, function(img) {
    img.setControlsVisibility(HideControls);
    img.set({
    transparentCorners: true,
    cornerColor: 'white',
    cornerStrokeColor: 'black',
    borderColor: 'black',
    cornerSize: 20,
    padding: 0,
    cornerStyle: 'circle',
    top:top,
    left:left,
    id:item.alphabetlondonid,
    name : 'alphabetlondon',
    angle:angle,
  });
    img.scale(scalex);
    canvas.add(img);
    canvas.renderAll();
},{crossOrigin: 'anonymous'}); 
});
    let sgexclusive  = sessionStorage.getItem('sg-exclusive');
    let sgexclusivearray = JSON.parse(sgexclusive);
    var HideControls = {
        'mb': false,
        'mr': false,
        'ml': false,
        'mt': false,
      };
    sgexclusivearray && sgexclusivearray.map((item,index)=>{
    let sgexclusiveindex =postioncordvalue && postioncordvalue.findIndex(items => item.sgexclusiveid == items.id)
    let top = 100;
    let left = 100;
    let angle = 0;  
    let scalex = 0.1
    if(postioncordvalue)
    {
        if(postioncordvalue[sgexclusiveindex])
        {
            top = postioncordvalue[sgexclusiveindex].imagecordtop;
            left = postioncordvalue[sgexclusiveindex].imagecordleft;
            angle = postioncordvalue[sgexclusiveindex].angle
            scalex =  postioncordvalue[sgexclusiveindex].scalex
        }
    }
    fabric.Image.fromURL(item.img, function(img) {
    img.setControlsVisibility(HideControls);
    img.set({
    transparentCorners: true,
    cornerColor: 'white',
    cornerStrokeColor: 'black',
    borderColor: 'black',
    cornerSize: 20,
    padding: 0,
    cornerStyle: 'circle',
    top:top,
    left:left,
    id:item.sgexclusiveid,
    name : 'sgexclusive',
    angle:angle,
    imgid : item.id
  });

    img.scale(scalex);
    canvas.add(img);
    canvas.renderAll();
},{crossOrigin: 'anonymous'}); 
});
    const{position} = this.state;
    canvas.on("object:modified", function(e) {
       //console.log("e",e)
    //    let image  = e.target._element.src
    //    let imagecordtop = e.target.top
    //    let imagecordleft = e.target.left
       let imageposition = position && position.filter(x=>x.id==e.target.id)
       if(imageposition.length == 0)
       {
        position.push({
            image : e.target._element.src,
            imagecordtop : e.target.top,
            imagecordleft : e.target.left,
            id : e.target.id,
            name : e.target.name,
            scalex : e.target.scaleX,
            scaley : e.target.scaleY,
            angle: e.target.angle,
        })
    }
    else{
        let value = position.findIndex(item => item.id == e.target.id)
        //console.log("value",value)
        position.splice(value, 1)
        position.push({
            image : e.target._element.src,
            imagecordtop : e.target.top,
            imagecordleft : e.target.left,
            id : e.target.id,
            name : e.target.name,
            scalex : e.target.scaleX,
            scaley : e.target.scaleY,
            angle: e.target.angle,
         })
    }
        sessionStorage.setItem('position',JSON.stringify(position))
    })
    // canvas.on("mouse:down", function(e) {
    //     setTimeout(() => {
    //         let obj = canvas.discardActiveObject();
    //         sessionStorage.setItem('deselectObject',obj)
    //         canvas.renderAll();
    //      }, 10000);
    // })
    canvas.on('selection:created', function(e) { 
        let object = canvas.getActiveObject();
        sessionStorage.setItem('activeobject',object.id)
    });
    canvas.on('selection:updated', function(e) { 
        let object = canvas.getActiveObject();
        sessionStorage.setItem('activeobject',object.id)
    });   
    canvas.on('selection:cleared', function(e) { 
       sessionStorage.removeItem('activeobject')
    });   
}
    render()
    {
        const{disablebutton,disabledelete, canvas}=this.state;
        return(
            <div>
                <Header/>
                <section className="home-register-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div design-menu-sec design-logo-sec design-layout-div">
                                    <div className="form-logo-section sec-center">
                                        <a href="#/register"><img src={iconsquare} alt="logo" className="img-fluid"/></a>
                                    </div>
                                    <h3>Layout</h3>
                                    <h4></h4>
                                    <div className="col-md-12 sec-center left">
                                        <div className="design-layout-grid-outer">
                                            <div className="design-layout-grid-box design-layout-grid-box-left">
                                                <div className="design-layout-grid-box-inner-left">
                                                {/* <canvas id="canvas" width="700" height="700"></canvas> */}
                                                {/* <canvas id="canvass" width="480" height="480"></canvas> */}
                                                    {/* <img src={addidaslogo} alt="layout" class="img-fluid"/> */}
                                                    <canvas id="canvas" width="480" height="480"></canvas>
                                                </div>
                                            </div>
                                            <div className="design-layout-grid-box design-layout-grid-box-right">
                                                <div className="design-layout-grid-box-inner-right">
                                                    <img src={whitefront} alt="layout" className="img-fluid"/>
                                                </div>
                                                <div className="grid-side-image-square">
                                                    <div className="black_border_box">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 sec-center left">
                                <div class="bottom-reset-section">
                                    <button href="#" class="layout_reset_btn" onClick={() => this.resetLayout()}><img src={resetpng}/></button>
                                    <button href="#" class="layout_delete_btn" disabled={disabledelete} onClick={()=>  this.deleteLayout()}><img src={trashpng}/></button>
                                </div>
                                    <div className="footer-btn footer-btn-three footer-btn-large"> 
                                        <div className="footer-left-btn"> 
                                            <button onClick={this.layoutback} className="black_btn">Back</button>
                                        </div>
                                        <div className="footer-center-btn"> 
                                            <button onClick={this.layoutAdd} className="black_btn">Add</button>
                                        </div>
                                        <div className="footer-right-btn"> 
                                            <button onClick={this.layoutprint} className="black_btn" disabled={disablebutton}>Print</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        )
    }
}
export default Layout;
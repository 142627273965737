import React from 'react'
import iconsquare from '../assets/images/icon-square.png'
import Header from '../header'
class Confirmation extends React.Component{
    constructor(props)
    {
        super(props)
        {
            this.state={
               
            }
        }
       this.confirmation = this.confirmation.bind(this);
    }
    confirmation()
    {
        let slug = sessionStorage.getItem('store');
        this.props.history.push('/create-design/'+slug);
        window.location.reload();
        sessionStorage.clear();
    }
    render()
    {
        return(
            <div>
                <Header/>
                <section className="home-register-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div thankyou-sec">
                                    <div className="form-logo-section sec-center">
                                        <a href="#/register"><img src={iconsquare} alt="logo" className="img-fluid brand-logo"/></a>
                                    </div>
                                    <h3>Confirmation</h3>
                                    <div className="thankyou-sec-inner">
                                        <h2> Thank you For your order</h2>
                                        <h5> You'll receive an sms once your order is ready for collection.</h5>
                                    </div>
                                    <div className="col-md-12 sec-center left">
                                        <div className="footer-btn"> 
                                            <div className="footer-center-btn"> 
                                                <button className="black_btn" onClick={this.confirmation}>Ok</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>   
            </div>
        )
    }
}
export default Confirmation;
import React from 'react'
import Header from '../header'
import iconsquare from '../assets/images/icon-square.png'
import axios from 'axios';
import config from '../config'
import { ToastContainer, toast } from 'react-toastify';
class Loginpin extends React.Component{
    constructor(props)
    {
        super(props);
        this.state={
            disablebutton: false,
            loginerror: '',
            loginloader : false
        }
        this.handleSubmit= this.handleSubmit.bind(this);
        this.handleChange=this.handleChange.bind(this)
    }
    handleChange(e)
    {
        let name=e.target.name
        let value=e.target.value
        this.setState({[name]:value})
    }
    handleSubmit(e)
    {
        this.setState({
            loginloader : true
        })
        e.preventDefault();
        this.setState({
            disablebutton : true
        })
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}login`
        var formData = new FormData();
        let store_id = sessionStorage.getItem('store_id');
        formData.append("is_ipad", '1');
        formData.append("password", this.state.pin);
        formData.append("store_id", store_id);
        axios.post(apiUrl , formData, {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
        if(response.data.success == 1)
        {
            this.setState({
                loginloader : false
            })
            let token = response.data.auth_token
            let user_id =response.data.session_id
            sessionStorage.setItem('token',token)
            sessionStorage.setItem('user_id',user_id)
            sessionStorage.setItem('logos',JSON.stringify([]));
            sessionStorage.setItem('graphics',JSON.stringify([]));
            sessionStorage.setItem('alphabetblack',JSON.stringify([]));
            sessionStorage.setItem('alphabetblue',JSON.stringify([]));
            sessionStorage.setItem('alphabetlondon',JSON.stringify([]));
            sessionStorage.setItem('sg-exclusive',JSON.stringify([]));
            sessionStorage.setItem('position',JSON.stringify([]));
            this.props.history.push('/register');
        }
        else{
            this.setState({
                disablebutton : false,
                loginerror : 'Login Failed',
                loginloader : false
            })
        }
        })
        .catch((err) => {  
        });
    }
    componentDidMount()
    {
        sessionStorage.clear();
        var url = this.props.location.pathname;
        var store = url.substring(url.lastIndexOf('/') + 1);
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}storeSlug`
        var formData = new FormData();
        formData.append("slug", '1');
        formData.append("slug_name", store);
        axios.post(apiUrl , formData, {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
        if(response.data.success == 1)
        {
            sessionStorage.setItem('store_id',response.data.data_id)
            sessionStorage.setItem('store',response.data.inserted_records.slug)
        }
        else{
            
        }
        })
        .catch((err) => {  
        });
    }
    render()
    {
        const{disablebutton, loginerror,loginloader} = this.state;
            return(
            <div>
                <Header/>
                <ToastContainer/>
                <section className="home-register-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div">
                                    <div className="form-logo-section sec-center">
                                        <a><img src={iconsquare} alt="logo" className="img-fluid brand-logo"/></a>
                                    </div>
                                    <h3>Login</h3>
                                    <form className="home-register-form" onSubmit={this.handleSubmit}>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="password" 
                                                className="form-control" 
                                                id="pin" 
                                                name="pin" 
                                                placeholder="PIN*" 
                                                onChange={this.handleChange}
                                                required
                                                />
                                                <div className={loginerror ? 'email-error-msg' : '' }>{loginerror}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 sec-center">
                                            <button 
                                            type="submit" 
                                            className="btn register-btn" 
                                            disabled={disablebutton}
                                            >
                                                Login
                                            </button>
                                            {
                                            loginloader ? 
                                            <div class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            :
                                            null
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Loginpin;
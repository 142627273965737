import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import iconsquare from '../assets/images/icon-square.png'
import Header from '../header'
import axios from 'axios';
import config from '../config'
class Register extends React.Component{
    constructor(props)
    {
        super(props)
        this.state={
            name:'',
            phone:'',
            email:'',
            registerloader:false
        }
        this.handleSubmit= this.handleSubmit.bind(this);
        this.handleChange=this.handleChange.bind(this)
    }
    handleChange(e)
    {
        let name=e.target.name
        let value=e.target.value
        this.setState({[name]:value})
        if(name == 'phone')
        {
            let phone = value;
            phone = phone.replace(/[^0-9]/g, '');
            if(phone.length == 8) { 
                this.setState({
                    isvalidph : true,
                    phmessage : 'Phone number is valid'
                })
                return false;
            }
            else
            {
                this.setState({
                    isvalidph : false,
                    phmessage : 'Phone number is not valid'
                })
                return false;
            }
        }
        const emailRegex = /\S+@\S+\.\S+/;
        if(name == 'email')
        {
            if (!emailRegex.test(value)) {
                this.setState({
                    isvalidemail:false,
                    emailmessage : 'Please enter a valid email'
                })
                return false;
              }
              else{
                this.setState({
                    isvalidemail:true,
                    emailmessage : 'Your email looks good!'
                })
              }
        }
    }
    handleSubmit(e)
    {
        e.preventDefault();
        this.setState({
            disablebutton : true,
            registerloader : true
        })
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}addCustomer`
        var formData = new FormData();
        formData.append("full_name", this.state.name);
        formData.append("phone", this.state.phone);
        formData.append("email", this.state.email);
        axios.post(apiUrl ,formData, {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
            if(response.data.success == 1)
            {
                this.setState({
                    registerloader : false
                })
                let customer_id = response.data.data_id
                sessionStorage.setItem('customer_id',customer_id)
                this.props.history.push('/tshirt-selection');
            }
            else{
                this.setState({
                    disablebutton : false
                })
            }
        })
        .catch((err) => {
        });
    }
    componentDidMount()
    {
        sessionStorage.setItem('logos',JSON.stringify([]));
        sessionStorage.setItem('graphics',JSON.stringify([]));
        sessionStorage.setItem('alphabetblack',JSON.stringify([]));
        sessionStorage.setItem('alphabetblue',JSON.stringify([]));
        sessionStorage.setItem('alphabetlondon',JSON.stringify([]));
        sessionStorage.setItem('sg-exclusive',JSON.stringify([]));
        sessionStorage.setItem('position',JSON.stringify([]));
        sessionStorage.removeItem('selectedTshirt');
        sessionStorage.removeItem('customer_id');
        sessionStorage.removeItem('material_id');
        sessionStorage.removeItem('imagedata');
        sessionStorage.removeItem('tempimg');
        sessionStorage.removeItem('prev_image');
    }
    render()
    {
        const {phmessage,emailmessage,isvalidph,isvalidemail,disablebutton,registerloader} = this.state;
        return(
            <div>
                <Header/>
                <ToastContainer />
                <section className="home-register-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div">
                                    <div className="form-logo-section sec-center">
                                        <a><img src={iconsquare} alt="logo" className="img-fluid brand-logo"/></a>
                                    </div>
                                    <h3>Registration</h3>
                                    <form className="home-register-form" onSubmit={this.handleSubmit}>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="text" 
                                                className="form-control" 
                                                id="name" 
                                                name="name" 
                                                placeholder="Name*" 
                                                onChange={this.handleChange}
                                                required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input 
                                                type="tel" 
                                                className="form-control" 
                                                id="phone" 
                                                name="phone"
                                                placeholder="Contact Number*" 
                                                maxLength="8"
                                                onChange={this.handleChange}
                                                required  
                                                />
                                                <div className={isvalidph ? 'email-success-msg' : 'email-error-msg' }>{phmessage}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input 
                                                type="email" 
                                                className="form-control" 
                                                id="email" 
                                                name="email" 
                                                placeholder="Email*" 
                                                onChange={this.handleChange}
                                                required
                                                />
                                                <div className={isvalidemail ? 'email-success-msg' : 'email-error-msg' }>{emailmessage}</div>
                                            </div> 
                                        </div>
                                        <div class="col-md-12">
	                                        <div class="terms-condition-section">
                                                <h5><strong>Terms and Conditions</strong></h5>
                                                <h5 class="h5_lighttxt">adidas Vivo City MakerLab</h5>
                                                <ul class="terms-ul">
                                                    <li><span>Customization services at MakerLab are only available for apparel, footwear and accessories
                                                    purchased from the following stores or online at adidas.com.sg:
                                                    <div class="terms-list-outer">
                                                        <div class="terms-list-grid">
                                                            <p>1. adidas Marina Bay Sands</p>
                                                            <p>2. adidas Suntec City</p>
                                                            <p>3. adidas Vivo City</p>
                                                            <p>4. adidas Originals Pacific Plaza</p>
                                                            <p>5. adidas Originals Paragon</p>
                                                        </div>
                                                        <div class="terms-list-grid">
                                                            <p>6. adidas Originals Bugis+</p>
                                                            <p>7. adidas Originals Vivo City</p>
                                                            <p>8. adidas Ion Orchard</p>
                                                            <p>9. adidas Paragon</p>
                                                            <p>10. adidas Bugis Junction</p>
                                                        </div>
                                                        <div class="terms-list-grid">
                                                            <p>11. adidas JEM</p>
                                                            <p>12. adidas Tampines One</p>
                                                            <p>13. adidas Causeway Point</p>
                                                            <p>14. adidas OG People’s Park</p>
                                                            <p>15. adidas Jewel</p>
                                                        </div>
                                                    </div>
                                                    </span>
                                                    </li>
                                                    <li><span>Products to be customised must be brand new and customization at MakerLab must be requested
                                                    within 14 days from the date of purchase. adidas Singapore staff reserves the right to refuse Products for
                                                    customization, in their sole discretion.</span></li>
                                                    <li><span>Proof of purchase or receipt for the Products to be customized must be presented to be eligible for the
                                                    MakerLab customization.</span></li>
                                                    <li><span>Advance payment for the customization must be made at the store cashier at MakerLab.</span></li>
                                                    <li><span>Customisation requests and patch placements recorded on MakerLab order form for Heat Press/Hand Jet
                                                    Printer or on the MakerLab app for Direct to Print are considered final. Changes shall not be entertained.</span></li>
                                                    <li><span>Customization layouts containing the following are strictly not allowed:
                                                    <div class="term-list-hypen">
                                                    <p>- Arrangement of characters into words or visuals that may be deemed offensive</p>
                                                    <p>- Phrases, quotes or sayings that are not the original work of the customer</p>
                                                    <p>- Trademarks or copyrighted works, logos, names, images, photos, music, lyrics, comics, cartoons,
                                                    characters and slogans - Sports teams, club college or organization logos</p>
                                                    <p>- Images downloaded from the internet, memes or the like</p>
                                                    </div>
                                                    </span>
                                                    </li>
                                                    <li><span>Strictly no refunds or exchange allowed.</span></li>
                                                    <li><span>Minimum of 30 minutes waiting is required depending on demand for the day</span></li>
                                                    <li><span>Designs and customization subject to review and approval of MakerLab staff. All decisions are final and
                                                    no correspondence will be entered into.</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group checkbox-div register-checkbox-div">
                                                <input type="checkbox" 
                                                id="checktxt" 
                                                name="click-txt" 
                                                value="" 
                                                required
                                                />
                                                <label htmlFor="checktxt">I have read and agree to the terms and conditions.  </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 sec-center">
                                            <button 
                                            type="submit" 
                                            className="btn register-btn" 
                                            disabled={disablebutton}>
                                                Register
                                            </button>
                                            {
                                            registerloader ? 
                                            <div class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            :
                                            null
                                            }
                                        </div>
                                    </form>
                                    <div class="form-bottom-text terms-form-bottom-text">
	                                    <p>By clicking Register, you have read and agreed to the adidas Privacy Notice</p>
	                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Register;
import React from 'react'
import iconsquare from '../assets/images/icon-square.png'
import Header from '../header'
import axios from 'axios';
import config from '../config'
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';
class Dashboardqueing extends React.Component{
    constructor(props)
    {
        super(props)
        this.state={
            offset: 0,
            data: [],
            perPage: 10,
            currentPage: 0,
            completeddata:[],
            sno:1,
            postData:[],
            reload : false
        }
        this.handlePageClick = this.handlePageClick.bind(this);
        this.printorder = this.printorder.bind(this);
        this.deleteorder = this.deleteorder.bind(this);
        this.completejob = this.completejob.bind(this);
    }
    receivedData(del)
    {
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}listOrder`
        var formData = new FormData();
        let store_id = sessionStorage.getItem('store_id')
        formData.append("getOrdersList", '1');
        formData.append("store_id", store_id);
        axios.post(apiUrl , formData, {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
        if(del == 'delete' && response.data.data.length == 0)
        {
            this.setState({
                reload : true
            })
        }
        //console.log("response",response)
        if(response.data.success == 1)
        {
            let data = response.data.data
            const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
            //console.log("slice",slice)
            let postData  = slice;
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),
                postData,
                dashboardloader : false
            })
        //    this.setState({
        //        data:data
        //    })
        }
        else{
            
        }
        })
        .catch((err) => {  
        });
    }
    completejob()
    {
        this.props.history.push('/dashboard/completed-jobs')
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        //console.log("selectedPage",selectedPage)
        const offset = selectedPage * this.state.perPage;
  
        this.setState({
            currentPage: selectedPage,
            offset: offset,
            sno : selectedPage == 0 ? selectedPage + 1 : selectedPage*10 + 1
        }, () => {
            this.receivedData()
        });
    };
    componentDidMount()
    {
        this.receivedData();
    }
    printorder(id)
    {
        //console.log("id",id);
        this.props.history.push({
            pathname: '/previewdetails'+'/'+id
        })
    }
    queuejobs()
    {
       this.props.history.push('/dashboard/queuing-jobs')
    }
    deleteorder(id)
    {
        if (window.confirm("Delete the order?")) {
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}deleteOrder`
        var formData = new FormData();
        formData.append("deleteOrder", '1');
        formData.append("orderId", id);
        axios.post(apiUrl , formData, {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
        //console.log("response",response)
        if(response.data.success == 1)
        {
            this.receivedData('delete');
            
        //    this.setState({
        //        data:data
        //    })
        }
        else{
             toast.error("Failed to Delete", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500
            });
        }
        })
        .catch((err) => {  
        });
        }
    }
    render()
    {
        const {postData,completedpostData, sno,dashboardloader, reload} = this.state;
        //console.log("this.state",this.state)
        return(
            <div>
                <Header/>
                <ToastContainer/>
                <section className="home-register-section login-section dashboard section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div queing-table-section">
                                    <div className="form-logo-section sec-center">
                                        <a><img src={iconsquare} alt="logo" className="img-fluid"/></a>
                                    </div>
                                    <div className="queing-table-menu">
                                        <ul>
                                            <li className={this.props.location.pathname == '/dashboard/queuing-jobs' ? "listjobs" : ""}><button onClick={(e)=> this.queuejobs()}>Queueing jobs</button></li>
                                            <li className={this.props.location.pathname == '/dashboard/completed-jobs' ? "listjobs" : ""}><button onClick={(e)=> this.completejob()}>Complete jobs</button></li>
                                        </ul>
                                    </div> 
                                    
                                    <div className="queing-table-inner">
                                    <div className="table-responsive">  
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th class="th-name">Name</th>
                                                <th>Contact Number</th>
                                                <th className="center">Status</th>
                                                <th className="center">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {postData && postData.map((item,index)=>{
                                                return (
                                                <tr key={index}>
                                                     {reload == true ? null :
                                                    <>
                                                    <td>{sno + index}</td>
                                                    <td>{item.full_name}</td>
                                                    <td>{item.phone}</td>
                                                    <td><button className="btn_complete" onClick={() => this.printorder(item.id)}>print</button></td>
                                                    <td><button className="btn_delete" onClick={() => this.deleteorder(item.id)}>delete</button></td>
                                                    </>
                                            }
                                                </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    </div>
                                    {postData.length == 0 || reload == true
                                    ?
                                    <p className="no-order">No Orders Found</p>
                                    :
                                    <ReactPaginate
                                        previousLabel={"prev"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                    />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        )
    }
}
export default Dashboardqueing;
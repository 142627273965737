import React from 'react'
import iconsquare from '../../assets/images/icon-square.png'
import Header from '../../header'
import axios from 'axios';
import config from '../../config'
import { v4 as uuidv4 } from 'uuid';
class Logos extends React.Component{
    constructor(props)
    {
        super(props)
        {
            this.state={
                data:[],
                logo:[],
                selectedItems:JSON.parse(sessionStorage.getItem('sg-exclusive')),
                count:1,
                sgexclusiveloader : false
            }
        }
        this.exclusiveback = this.exclusiveback.bind(this)
        this.exclusivenext = this.exclusivenext.bind(this)
        this.selectexclusive = this.selectexclusive.bind(this)
    }
    exclusiveback()
    {
        this.props.history.push('/designs');
    }
    exclusivenext()
    {
        this.props.history.push('/layout');
    }
    componentDidMount()
    {
        this.setState({
            sgexclusiveloader : true
        })
        let store_id = sessionStorage.getItem('store_id');
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}getAssests?type=sg-exclusive&store_id=${store_id}`
        axios.get(apiUrl , {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
            if(response.data.success == 1)
            {
                this.setState({
                    sgexclusiveloader : false
                })
                this.setState({data:response.data.data})
            }
        })
        .catch((err) => {

        });
    }
    selectexclusive(id,src,index, value)
    {
        const {selectedItems } = this.state;
        if(value == 1)
        {
        selectedItems.push({
            id:id,
            sgexclusiveid : uuidv4(),
            img:src
        })
          this.setState({
              tempstate:selectedItems
          })
          const filtered = selectedItems.filter(el => {
            return el != null && el != '';
          });
          sessionStorage.setItem('sg-exclusive',JSON.stringify(filtered));
        }
        else{
            let i=0;
            for(i= 0; i <= selectedItems.length;i++)
            {
                if(selectedItems[i])
                {
                    if(selectedItems[i].id == id)
                    {
                        delete selectedItems[i];
                        break;
                    }
                }
            }
            var filtered = selectedItems.filter(function (el) {
                return el != null;
            });
            this.setState({
                tempstate:selectedItems
            })
            sessionStorage.setItem('sg-exclusive',JSON.stringify(filtered));
        }
    }
    render()
    {
       const {selectedItems, count, sgexclusiveloader}  = this.state;
        return(
            <div>
                <Header/>
                <section className="home-register-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div design-menu-sec design-logo-sec">
                                    <div className="form-logo-section sec-center">
                                        <a href="#/register"><img src={iconsquare} alt="logo" className="img-fluid brand-logo"/></a>
                                    </div>
                                    <h3>Designs</h3>
                                    <h4>SG-exclusive</h4>
                                    {sgexclusiveloader ? 
                                    <div class="spinner-border logo" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <div>
                                        <div className="col-md-12 sec-center left">
                                            <div className="design-logo-grid-outer">
                                                <div className="design-logo-grid-inner">
                                                    {this.state.data && this.state.data.map((item, index)=>{
                                                         let imgpath = item.image_path
                                                         var imgname = imgpath.substring(imgpath.lastIndexOf('/') + 1);
                                                         let imagename = imgname.split('.png')
                                                        return(
                                                            <div key={index} className="logo-grid-box">
                                                                <div className={selectedItems.filter(x=>x.id==item.id).length > 0 ? "logo-grid-box-img active" : "logo-grid-box-img"}>
                                                                    <img id={item.id} src={item.image_path} className="img-fluid"/>
                                                                </div>
                                                                <div className="logo-grid-box-content">
                                                                    <h5>{imagename}</h5>
                                                                </div>
                                                                <ul>
                                                                    <li><button disabled={selectedItems.filter(x=>x.id==item.id).length == 0} onClick={(e) => this.selectexclusive(item.id,item.image_path,'-1')}>-</button></li>
                                                                    <li>{selectedItems.filter(x=>x.id==item.id).length}</li>
                                                                    <li><button disabled={selectedItems.filter(x=>x.id==item.id).length == 5} onClick={(e) => this.selectexclusive(item.id,item.image_path,index,'1')}>+</button></li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
	                                <div className="col-md-12 sec-center left">
	                                    <div className="footer-btn"> 
	                                        <div className="footer-left-btn"> 
	                                            <button onClick={this.exclusiveback} className="black_btn">Back</button>
	                                        </div>
	                                        <div className="footer-right-btn"> 
	                                            <button onClick={this.exclusivenext} className="black_btn">Next</button>
	                                        </div>
	                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Logos;
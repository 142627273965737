import React from 'react'
import Header from '../header'
import iconsquare from '../assets/images/icon-square.png'
import axios from 'axios';
import config from '../config'
import { ToastContainer, toast } from 'react-toastify';
class TshirtSelection extends React.Component{
    constructor(props)
    {
        super(props)
        this.state={
            cutting:'',
            // size:'',
            side:'',
            color:'',
            selected:{},
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleSubmit(e)
    {
        e.preventDefault();
        let selectedTshirt=[];
        selectedTshirt.push({
            cutting:this.state.cutting,
            // size: this.state.size,
            side : this.state.side,
            color: this.state.color
        })
        this.setState({
            customizeloader : true
        })
        sessionStorage.setItem('selectedTshirt', JSON.stringify(selectedTshirt));
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}addMaterial`
        var formData = new FormData();
        formData.append("name", this.state.cutting);
        formData.append("type", this.state.side);
        // formData.append("size", this.state.size);
        formData.append("color", this.state.color);
        axios.post(apiUrl , formData, {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
            if(response.data.success == 1)
            {
                let material_id = response.data.data_id
                sessionStorage.setItem('material_id',material_id)
                this.setState({
                    customizeloader : false
                })
                this.props.history.push('/designs')
            }
            else{
                
            }
        })
        .catch((err) => {
           
        });
    }
    handleChange(e)
    {
        let name=e.target.name
        let value=e.target.value
        this.setState({[name]:value})
    }
    componentDidMount()
    {
        let getselectedvalues = sessionStorage.getItem('selectedTshirt')
        let getselected = JSON.parse(getselectedvalues);
        if(getselected)
        {
            let selected = getselected[0];
            this.setState({
            selected : selected,
            cutting : selected.cutting,
            // size : selected.size,
            side : selected.side,
            color : selected.color
        })
        }
    }
    render()
    {
        let Cstate=Object.values(this.state);
        let Istate =Cstate.map(x => x ?true:false).filter( x => x)
        const {customizeloader} = this.state;
        return(
            <div>
                <Header/>
                <ToastContainer/>
                <section className="home-register-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div selct-form-div">
                                    <div className="form-logo-section sec-center">
                                        <a href="#/register"><img src={iconsquare} alt="logo" className="img-fluid brand-logo"/></a>
                                    </div>
                                    <h3>T-SHIRT SELECTION</h3>
                                    <form className="home-register-form" onSubmit={this.handleSubmit}>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <div className="radio-section radio-1-section">
                                                    <div className="radio-head">
                                                        <p>Cutting<span>*</span></p>
                                                        </div>
                                                        <div className="radio-section-inner">
                                                            <div className="radio-left">
                                                                <input id="radio-1" className="radio-custom" name="cutting" value="Male" type="radio" onChange={this.handleChange} checked={this.state.cutting == 'Male' ? true : false}/>
                                                                <label htmlFor="radio-1" className="radio-custom-label">Male</label>
                                                            </div>
                                                            <div className="radio-right">
                                                                <input id="radio-2" className="radio-custom" name="cutting" value="Female" type="radio" onChange={this.handleChange} checked={this.state.cutting == 'Female' ? true : false}/>
                                                                <label htmlFor="radio-2" className="radio-custom-label">Female</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="radio-section radio-2-section">
                                                        <div className="radio-head">
                                                            <p>Size<span>*</span></p>
                                                            </div>
                                                            <div className="radio-section-inner">
                                                                <div className="radio-left">
                                                                    <input id="radio-3" className="radio-custom" name="size" value="Small" type="radio" onChange={this.handleChange} checked={this.state.size == 'Small' ? true : false}/>
                                                                        <label htmlFor="radio-3" className="radio-custom-label">Small</label>
                                                                        </div>
                                                                        <div className="radio-center">
                                                                            <input id="radio-4" className="radio-custom"name="size" value="Medium" type="radio" onChange={this.handleChange} checked={this.state.size == 'Medium' ? true : false}/>
                                                                            <label htmlFor="radio-4" className="radio-custom-label">Medium</label>
                                                                            </div>
                                                                            <div className="radio-right">
                                                                                <input id="radio-5" className="radio-custom"name="size" value="Large" type="radio" onChange={this.handleChange} checked={this.state.size == 'Large' ? true : false}/>
                                                                                <label htmlFor="radio-5" className="radio-custom-label">Large</label>
                                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="radio-section radio-1-section">
                                                            <div className="radio-head">
                                                                <p>Print Side<span>*</span></p>
                                                            </div>
                                                                <div className="radio-section-inner">
                                                                    <div className="radio-left">
                                                                        <input id="radio-6" className="radio-custom" name="side" value="T-Shirt Front" type="radio" onChange={this.handleChange} checked={this.state.side == 'T-Shirt Front' ? true : false}/>
                                                                        <label htmlFor="radio-6" className="radio-custom-label">T-Shirt Front</label>
                                                                    </div>
                                                                    <div className="radio-right">
                                                                        <input id="radio-7" className="radio-custom" name="side" value="T-Shirt Back" type="radio" onChange={this.handleChange} checked={this.state.side == 'T-Shirt Back' ? true : false}/>
                                                                        <label htmlFor="radio-7" className="radio-custom-label">T-Shirt Back</label>
                                                                    </div>
                                                                </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="radio-section radio-3-section">
                                                            <div className="radio-head">
                                                                <p>Color<span>*</span></p>
                                                            </div>
                                                            <div className="radio-section-inner">
                                                                {/* <div className="radio-left">
                                                                    <input id="radio-8" className="radio-custom" name="color" type="radio"/>
                                                                    <label htmlFor="radio-8" className="radio-custom-label">Dark Blue</label>
                                                                <div className="color_box blue_box"></div>
                                                            </div> */}
                                                            <div className="radio-center">
                                                                <input id="radio-9" className="radio-custom"name="color" value="Gray" type="radio" onChange={this.handleChange} checked={this.state.color == 'Gray' ? true : false}/>
                                                                <label htmlFor="radio-9" className="radio-custom-label">Gray</label>
                                                                <div className="color_box gray_box"></div>
                                                            </div>
                                                            <div className="radio-right">
                                                                <input id="radio-10" className="radio-custom"name="color" value="Black" type="radio" onChange={this.handleChange} checked={this.state.color == 'Black' ? true : false}/>
                                                                <label htmlFor="radio-10" className="radio-custom-label">Black</label>
                                                                <div className="color_box black_box"></div>
                                                            </div>
                                                            <div className="radio-right-last">
                                                                <input id="radio-11" className="radio-custom"name="color" value="White" type="radio" onChange={this.handleChange} checked={this.state.color == 'White' ? true : false}/>
                                                                <label htmlFor="radio-11" className="radio-custom-label">White</label>
                                                                <div className="color_box white_box"></div>
                                                            </div>
		                                                </div>
                                                    </div>
                                                 </div>
	                                        </div>
                                            <div className="col-md-12 sec-center next-btn-section">
                                                <button type="submit" className="btn register-btn next-btn" disabled={Cstate.length != Istate.length}>Next</button>
                                                {
                                                customizeloader ? 
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                :
                                                null
                                                }
                                            </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default TshirtSelection;
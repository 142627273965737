import { Route , useParams, Redirect} from 'react-router-dom';
import React from 'react';
import Register from '../login/Register';
import TshirtSelection from '../Tshirt-selection/tshirtselection';
import Designs from '../Tshirt-selection/designs/designs';
import Logos from '../Tshirt-selection/designs/logos'
import Graphics from '../Tshirt-selection/designs/graphics'
import Alphabets from '../Tshirt-selection/designs/alphabets'
import AlphabetsBlack from '../Tshirt-selection/designs/alphabets-black'
import AlphabetsBlue from '../Tshirt-selection/designs/alphabets-blue'
import AlphabetsLondon from '../Tshirt-selection/designs/alphabets-london'
import Exclusiveicons from '../Tshirt-selection/designs/exclusive-icon'
import Layout from '../Tshirt-selection/layout';
import Tshirtfront from '../Tshirt-selection/t-shirt-front';
import Tshirtback from '../Tshirt-selection/t-shirt-back';
import Loginpin from '../login/Loginpin';
import Confirmation from '../Tshirt-selection/confirmation'
import ManageadminLogin from '../admin/manageadminlogin'
import Dashboardqueing from '../admin/dashboard-queingjobs'
import Dashboardcompleted from '../admin/dashboard-completedjobs'
import PreviewDetails from '../admin/previewDetails'
import Media from '../admin/media'
export default () => [
    <Route key="retail01" exact path="/create-design/retail01" component={Loginpin}/>,
    <Route key="retail02" exact path="/create-design/retail02" component={Loginpin}/>,
    <Route key="register" path="/register" render={props => (
        sessionStorage.getItem('token')
           ? <Register {...props} />
           : <Redirect to={{ pathname: '/create-design/'+sessionStorage.getItem('store')}} />
        )} />,
   <Route key="tshirt-selection" path="/tshirt-selection" render={props => (
    sessionStorage.getItem('token')
       ? <TshirtSelection {...props} />
       : <Redirect to={{ pathname: '/create-design/'+sessionStorage.getItem('store')}} />
        )} />,
    <Route key="designs" path="/designs" render={props => (
        sessionStorage.getItem('token')
       ? <Designs {...props} />
       : <Redirect to={{ pathname: '/create-design/'+sessionStorage.getItem('store')}} />
        )} />,
    <Route key="logos" path="/logos" render={props => (
        sessionStorage.getItem('token')
           ? <Logos {...props} />
           : <Redirect to={{ pathname: '/create-design/'+sessionStorage.getItem('store')}} />
        )} />,
        <Route key="graphics" path="/graphics" render={props => (
            sessionStorage.getItem('token')
               ? <Graphics {...props} />
               : <Redirect to={{ pathname: '/create-design/'+sessionStorage.getItem('store')}} />
        )} />,
        <Route key="alphabets" path="/alphabets" render={props => (
            sessionStorage.getItem('token')
                ? <Alphabets {...props} />
                : <Redirect to={{ pathname: '/create-design/'+sessionStorage.getItem('store')}} />
        )} />,
        <Route key="alphabets-black" path="/alphabets-black" render={props => (
            sessionStorage.getItem('token')
                    ? <AlphabetsBlack {...props} />
                    : <Redirect to={{ pathname: '/create-design/'+sessionStorage.getItem('store')}} />
        )} />,
        <Route key="alphabets-blue" path="/alphabets-blue" render={props => (
            sessionStorage.getItem('token')
                ? <AlphabetsBlue {...props} />
                : <Redirect to={{ pathname: '/create-design/'+sessionStorage.getItem('store')}} />
        )} />,
        <Route key="alphabets-london" path="/alphabets-london" render={props => (
            sessionStorage.getItem('token')
                ? <AlphabetsLondon {...props} />
                : <Redirect to={{ pathname: '/create-design/'+sessionStorage.getItem('store')}} />
        )} />,
        <Route key="exclusiveicons" path="/exclusiveicons" render={props => (
            sessionStorage.getItem('token')
                ? <Exclusiveicons {...props} />
                : <Redirect to={{ pathname: '/create-design/'+sessionStorage.getItem('store')}} />
        )} />,
        <Route key="layout" path="/layout" render={props => (
            sessionStorage.getItem('token')
                ? <Layout {...props} />
                : <Redirect to={{pathname: '/create-design/'+sessionStorage.getItem('store')}} />
        )} />,
        <Route key="tshirt-front" path="/tshirt-front" render={props => (
            sessionStorage.getItem('token')
                ? <Tshirtfront {...props} />
                : <Redirect to={{pathname: '/create-design/'+sessionStorage.getItem('store')}} />
        )} />,
        <Route key="tshirt-back" path="/tshirt-back" render={props => (
            sessionStorage.getItem('token')
                ? <Tshirtback {...props} />
                : <Redirect to={{ pathname: '/create-design/'+sessionStorage.getItem('store')}} />
        )} />,
        <Route key="confirmation" path="/confirmation" render={props => (
            sessionStorage.getItem('token')
                ? <Confirmation {...props} />
                : <Redirect to={{ pathname: '/create-design/'+sessionStorage.getItem('store')}} />
        )} />,
        /*   admin    */
        <Route key="login" exact path="/staff/retail01" component={ManageadminLogin}/>,
        <Route key="login" exact path="/staff/retail02" component={ManageadminLogin}/>,
        <Route key="login" path="/admin/assets/retail01" component={ManageadminLogin} />,
        <Route key="login" path="/admin/assets/retail02" component={ManageadminLogin} />,
        <Route key="dashboard" path="/dashboard/queuing-jobs" render={props => (
          sessionStorage.getItem('admin_token')
               ? <Dashboardqueing {...props} />
               : <Redirect to={{ pathname: '/'}} />
       )} />,
       <Route key="dashboard" path="/dashboard/completed-jobs" render={props => (
          sessionStorage.getItem('admin_token')
           ? <Dashboardcompleted {...props} />
           : <Redirect to={{ pathname: '/staff/'+sessionStorage.getItem('store')}} />
    )} />,
       <Route key="previewdetails" path="/previewdetails" render={props => (
          sessionStorage.getItem('admin_token')
           ? <PreviewDetails {...props} />
           : <Redirect to={{ pathname: '/staff/'+sessionStorage.getItem('store')}} />
        )} />,

        /* assets admin */
        <Route key="media" path="/admin/assets/media" render={props => (
          sessionStorage.getItem('assets_token')
               ? <Media {...props} />
               : <Redirect to={{ pathname: '/admin/assets/'+sessionStorage.getItem('store')}} />
       )} />,
       <Route key="alphabetsmedia" path="/admin/assets/media/alphabets" render={props => (
          sessionStorage.getItem('assets_token')
             ? <Media {...props} />
             : <Redirect to={{ pathname: '/admin/assets/'+sessionStorage.getItem('store')}} />
     )} />,
       <Route key="addmedia" path="/admin/assets/media/add" render={props => (
          sessionStorage.getItem('assets_token')
           ? <Media {...props} />
           : <Redirect to={{ pathname: '/admin/assets/'+sessionStorage.getItem('store')}} />
    )} />,
];

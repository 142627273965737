'use strict';

// App config the for development environment.
export default {
    app: {
        title: 'Adidas',
        secure: 'https',
        host:'tshirt.cns72.com', //Demo Server
        //port:8000
    },
    api: {     
        //host:'https://f0zxytd5jk.execute-api.us-east-2.amazonaws.com/',
        host: 'https://tshirt.cns72.com/api/',
        apiversion: "api/v1",
        port:'3001'        
    },   
};
// 'use strict';

// // App config the for development environment.
// export default {
//     app: {
//         title: 'Adidas MakerLAB',
//         secure: 'https',
//         host:'https://adidasmakerlabsg.com', //production Server
//         //port:8000
//     },
//     api: {     
//         host: 'https://adidasmakerlabsg.com/api/',
//         apiversion: "api/v1",
//         port:'3001'        
//     },   
// };
import React from 'react'
import iconsquare from '../../assets/images/icon-square.png'
import Header from '../../header'
import axios from 'axios';
import config from '../../config'
import { v4 as uuidv4 } from 'uuid';
class Logos extends React.Component{
    constructor(props)
    {
        super(props)
        {
            this.state={
                data:[],
                logo:[],
                selectedItems:JSON.parse(sessionStorage.getItem('logos')),
                logoloader : false,
                count : 1
            }
        }
        this.logosback = this.logosback.bind(this)
        this.logosnext = this.logosnext.bind(this)
        this.selectlogo = this.selectlogo.bind(this)
    }
    logosback()
    {
        this.props.history.push('/designs');
    }
    logosnext()
    {
        this.props.history.push('/graphics');
    }
    componentDidMount()
    {
        this.setState({
            logoloader : true
        })
        let store_id = sessionStorage.getItem('store_id');
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}getAssests?type=logos&store_id=${store_id}`
        axios.get(apiUrl , {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
            if(response.data.success == 1)
            {
                this.setState({
                    logoloader : false
                })
                this.setState({data:response.data.data})
            }
        })
        .catch((err) => {

        });
    }
    selectlogo(id,src,index, value)
    {
        const {selectedItems } = this.state;
        if(value == 1)
        {
        selectedItems.push({
            id:id,
            logoid : uuidv4(),
            img:src
        })
          this.setState({
              tempstate:selectedItems
          })
          const filtered = selectedItems.filter(el => {
            return el != null && el != '';
          });
          sessionStorage.setItem('logos',JSON.stringify(filtered));
        }
        else{
            let i=0;
            for(i= 0; i <= selectedItems.length;i++)
            {
                if(selectedItems[i])
                {
                    if(selectedItems[i].id == id)
                    {
                        delete selectedItems[i];
                        break;
                    }
                }
            }
            const filtered = selectedItems.filter(el => {
                return el != null && el != '';
              });
            this.setState({
                tempstate:selectedItems
            })
            sessionStorage.setItem('logos',JSON.stringify(filtered));
        }
    }
    render()
    {
       const {selectedItems,logoloader, count}  = this.state;
        return(
            <div>
                <Header/>
                <section className="home-register-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div design-menu-sec design-logo-sec">
                                    <div className="form-logo-section sec-center">
                                        <a href="#/register"><img src={iconsquare} alt="logo" className="img-fluid brand-logo"/></a>
                                    </div>
                                    <h3>Designs</h3>
                                    <h4>Logos</h4>
                                    {logoloader ? 
                                    <div class="spinner-border logo" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <div>
                                        <div className="col-md-12 sec-center left">
                                            <div className="design-logo-grid-outer">
                                                <div className="design-logo-grid-inner">
                                                    {this.state.data && this.state.data.map((item, index)=>{
                                                        return(
                                                        <div key={index} className="logo-grid-box">
                                                            <div className={selectedItems.filter(x=>x.id==item.id).length > 0 ? "logo-grid-box-img active" : "logo-grid-box-img"}>
                                                                <img id={item.id} src={item.image_path} className="img-fluid"/>
                                                            </div>
                                                            <div className="logo-grid-box-content">
                                                                <h5>Logo {count + index}</h5>
                                                            </div>
                                                            <ul>
                                                                <li><button disabled={selectedItems.filter(x=>x.id==item.id).length == 0} onClick={(e) => this.selectlogo(item.id,item.image_path,'-1')}>-</button></li>
                                                                <li>{selectedItems.filter(x=>x.id==item.id).length}</li>
                                                                <li><button disabled={selectedItems.filter(x=>x.id==item.id).length == 5} onClick={(e) => this.selectlogo(item.id,item.image_path,index,'1')}>+</button></li>
                                                            </ul>
                                                        </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
	                                <div className="col-md-12 sec-center left">
	                                    <div className="footer-btn"> 
	                                        <div className="footer-left-btn"> 
	                                            <button onClick={this.logosback} className="black_btn">Back</button>
	                                        </div>
	                                        <div className="footer-right-btn"> 
	                                            <button onClick={this.logosnext} className="black_btn">Next</button>
	                                        </div>
	                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            
        )
    }
}
export default Logos;
import React from 'react'
import iconsquare from '../../assets/images/icon-square.png'
import Header from '../../header'
import Blackfont from '../../assets/images/alphabets/BLACK-FONT/A.png'
import Blueredfont from '../../assets/images/alphabets/BLUE-RED-FONT/A.png'
import Londonfont from '../../assets/images/alphabets/LONDON-FONT/A.png'
class Alphabets extends React.Component{
    constructor(props)
    {
        super(props)
        {
            this.state={

            }
        }
        this.alphabetsback = this.alphabetsback.bind(this)
        this.alphabetsnext = this.alphabetsnext.bind(this)
        this.Blackfont = this.Blackfont.bind(this);
        this.Bluefont=this.Bluefont.bind(this);
        this.Londonfont = this.Londonfont.bind(this);
    }
    alphabetsback()
    {
        this.props.history.push('/designs');
    }
    alphabetsnext()
    {
        this.props.history.push('/alphabets-black');
    }
    Blackfont()
    {
        this.props.history.push('/alphabets-black');
    }
    Bluefont()
    {
        this.props.history.push('/alphabets-blue');
    }
    Londonfont()
    {
        this.props.history.push('/alphabets-london');
    }
    render()
    {
        return(
            <div>
                <Header/>
                <section className="home-register-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div design-menu-sec design-logo-sec design-alphabet-sec">
                                    <div className="form-logo-section sec-center">
                                        <a href="#/register"><img src={iconsquare} alt="logo" className="img-fluid brand-logo"/></a>
                                    </div>
                                    <h3>Designs</h3>
                                    <h4></h4>
                                    <div className="col-md-12 sec-center left">
                                        <div className="design-logo-grid-outer design-alphabets-grid-outer">
                                            <div className="design-logo-grid-inner">
                                                <div className="logo-grid-box">
                                                    <div className="logo-grid-box-img" onClick={this.Blackfont}>
                                                        <img src={Blackfont} alt="logo1" className="img-fluid"/>
                                                    </div>
                                                </div>
                                                <div className="logo-grid-box">
                                                    <div className="logo-grid-box-img"  onClick={this.Bluefont}>
                                                        <img src={Blueredfont} alt="logo1" className="img-fluid"/>
                                                    </div>
                                                </div>
                                                <div className="logo-grid-box">
                                                    <div className="logo-grid-box-img" onClick={this.Londonfont}>
                                                        <img src={Londonfont} alt="logo1" className="img-fluid"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 sec-center left">
                                        <div className="footer-btn"> 
                                            <div className="footer-left-btn"> 
                                                <button onClick={this.alphabetsback} className="black_btn">Back</button>
                                            </div>
                                            <div className="footer-right-btn"> 
                                                <button onClick={this.alphabetsnext} className="black_btn">Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Alphabets;